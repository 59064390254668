export type ScaleType = 'STRETCH' | 'CENTER' | { xOffset: number, yOffset: number };

export type PosterSize = 
'50x50' | 
'13x18' | '21x30' | '30x40' | '50x70' | '50x75' | '70x100' | '80x120' |
'18x13' | '30x21' | '40x30' | '70x50' | '75x50' | '100x70' | '120x80';

export const squarePosterSizes = ['50x50'];
export const squareCanvasSizes = ['50x50'];

export const verticalPosterSizes = ['13x18', '21x30', '30x40', '50x70', '70x100'];
export const verticalCanvasSizes = ['30x40', '50x70', '50x75', '70x100', '80x120'];

export const horizontalPosterSizes = ['18x13', '30x21', '40x30', '70x50', '100x70'];
export const horizontalCanvasSizes = ['40x30', '70x50', '75x50', '100x70', '120x80'];


export const allPosterSizes = squarePosterSizes.concat(verticalPosterSizes, horizontalPosterSizes);
export const allCanvasSizes = squareCanvasSizes.concat(verticalCanvasSizes, horizontalCanvasSizes);


export const posterSizesByName = {
    // Vertical 
    '13x18': {
        width: 13,
        height: 18,
        mmWidth: 136,
        mmHeight: 186
    },
    '21x30': {
        width: 21,
        height: 30,
        mmWidth: 216,
        mmHeight: 306
    },
    '30x40': {
        width: 30,
        height: 40,
        mmWidth: 306,
        mmHeight: 406
    },

    '50x70': {
        width: 50,
        height: 70,
        mmWidth: 506,
        mmHeight: 706
    },
    '70x100': {
        width: 70,
        height: 100,
        mmWidth: 706,
        mmHeight: 1006
    },

    //Square
    '50x50': {
        width: 50,
        height: 50,
        mmWidth: 506,
        mmHeight: 506
    },


    // Horizontal
    '18x13': {
        width: 18,
        height: 13,
        mmWidth: 186,
        mmHeight: 136
    },
    '30x21': {
        width: 30,
        height: 21,
        mmWidth: 306,
        mmHeight: 216
    },
    '40x30': {
        width: 40,
        height: 30,
        mmWidth: 406,
        mmHeight: 306
    },
    '70x50': {
        width: 70,
        height: 50,
        mmWidth: 706,
        mmHeight: 506
    },
    '100x70': {
        width: 100,
        height: 70,
        mmWidth: 1006,
        mmHeight: 706
    }


}


export const canvasSizesByName = {
    /*    '20x30': {
            width: 20,
            height: 30,
            mmWidth: 262,
            mmHeight: 362
        },*/

    // Vertical
    '30x40': {
        width: 30,
        height: 40,
        mmWidth: 362,
        mmHeight: 462
    },
    '50x70': {
        width: 50,
        height: 70,
        mmWidth: 562,
        mmHeight: 762
    },
    '50x75': {
        width: 50,
        height: 75,
        mmWidth: 562,
        mmHeight: 812
    },
    '70x100': {
        width: 70,
        height: 100,
        mmWidth: 762,
        mmHeight: 1062
    },
    '80x120': {
        width: 80,
        height: 120,
        mmWidth: 862,
        mmHeight: 1262
    },

    // Square
    '50x50': {
        width: 50,
        height: 50,
        mmWidth: 562,
        mmHeight: 562
    },

    // Horizontal
    '40x30': {
        width: 40,
        height: 30,
        mmWidth: 462,
        mmHeight: 362
    },
    '70x50': {
        width: 70,
        height: 50,
        mmWidth: 762,
        mmHeight: 562
    },
    '75x50': {
        width: 75,
        height: 50,
        mmWidth: 812,
        mmHeight: 562
    },
    '100x70': {
        width: 100,
        height: 70,
        mmWidth: 1062,
        mmHeight: 762
    },
    '120x80': {
        width: 120,
        height: 80,
        mmWidth: 1262,
        mmHeight: 862
    },

}

export const posterSizes = [
    posterSizesByName['13x18'],
    posterSizesByName['21x30'],
    posterSizesByName['30x40'],
    posterSizesByName['50x50'],
    posterSizesByName['50x70'],
    posterSizesByName['70x100']
];