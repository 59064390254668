import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Api } from 'src/app/services/api';
import { Globals } from 'src/app/services/globals';
import { PosterSize, horizontalCanvasSizes, horizontalPosterSizes, verticalPosterSizes } from '../../../../../../../common/common-types/poster-size';
import { IPosterAlignment } from 'src/app/interfaces/poster-alignment';
import { PosterFrameMaterial } from '../../../../../../../common/common-types/poster-frame';
import { BildbyranTermsDialogComponent } from './bildbyran-terms-dialog/bildbyran-terms-dialog.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'bildbyran-checkout',
  templateUrl: './bildbyran-checkout.component.html',
  styleUrls: ['./bildbyran-checkout.component.scss']
})
export class BildbyranCheckoutComponent implements OnInit {
  paramsSubscription: Subscription = null;
  imageId: string;
  imageUrl: string;
  orderId: string;
  initializingPayment: boolean = false;
  paymentFinished: boolean = false;

  // For size selector
  availableSizes: PosterSize[];
  sizePrices: { [size: string]: number };
  framePrices: { [size: string]: { [color: string]: number } };
  selectedFrame: PosterFrameMaterial | 'none';



  // For alignment configurator
  selectedSize: PosterSize;
  posterAlignment: IPosterAlignment = { xOffset: 0, yOffset: 0 };
  imageOrientation: 'portrait' | 'landscape';

  imageData: { width: number, height: number };

  constructor(private api: Api, public globals: Globals, private sanitizer: DomSanitizer, private route: ActivatedRoute, private dialog: MatDialog) {

  }

  async ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(async (params) => {
      this.imageId = params['imageId'];
      this.imageUrl = `${this.globals.baseUrl}/api/stores/public/${this.globals.storeId}/third-party/bildbyran/image/${this.imageId}/thumb`;
      this.imageData = await this.api.store().get<any>(`/third-party/bildbyran/image/${this.imageId}/data`);
      this.imageOrientation = this.imageData.width > this.imageData.height ? 'landscape' : 'portrait';

      //this.availableSizes = (this.imageOrientation == 'portrait' ? verticalPosterSizes : horizontalPosterSizes) as PosterSize[];

      this.availableSizes = (this.imageOrientation == 'portrait' ? ['21x30', '30x40', '50x70', '70x100'] : ['30x21', '40x30', '70x50', '100x70']) as PosterSize[];

      this.sizePrices = this.globals.storeSettings.defaultSizePrices.poster;
      for (let size in this.sizePrices) {
        let sizeSplit = size.split('x');
        this.sizePrices[sizeSplit[1] + 'x' + sizeSplit[0]] = this.sizePrices[size];
      }

      this.framePrices = this.globals.storeSettings.defaultSizePrices.frames;
      for (let size in this.framePrices) {
        let sizeSplit = size.split('x');
        this.framePrices[sizeSplit[1] + 'x' + sizeSplit[0]] = this.framePrices[size];
      }


      (window as any).gtag("event", "bb_image_viewed", {
        "event_category": "bb_image",
        "value": this.imageId
      });

    });
  }

  async proceedToPayment() {
    this.initializingPayment = true;
    let result = await this.api.store().post<any>(`/third-party/bildbyran/order`, {
      imageId: this.imageId,
      size: this.selectedSize,
      frame: this.selectedFrame,
      orientation: this.imageOrientation,
      posterAlignment: this.posterAlignment
    });

    if (result.succeeded) {
      this.orderId = result.orderId;
    }
    else {
      alert('Någonting gick fel. Försök igen senare.');
    }

    (window as any).gtag("event", "proceed_to_payment");
  }

  getPrice() {
    let price = this.sizePrices[this.selectedSize];

    if (this.selectedFrame && this.selectedFrame != 'none')
      price += this.framePrices[this.selectedSize][this.selectedFrame];

    return price;
  }


  async isPaid() {
    this.paymentFinished = true;
  }

  async backToSizes() {
    delete this.selectedSize;
    delete this.selectedFrame;
  }

  async sizeSelected(size) {
    this.selectedSize = size;
    delete this.selectedFrame;

    (window as any).gtag("event", "bb_size_selected", {
      "event_category": "bb_size",
      "value": size
    });
  }

  async frameSelected(frame) {
    this.selectedFrame = frame;

    if (this.imageOrientation == 'portrait')
      this.proceedToPayment();

    (window as any).gtag("event", "bb_frame_selected", {
      "event_category": "bb_frame",
      "value": frame
    });
  }


  async showTerms() {
    let dialogRef = this.dialog.open(BildbyranTermsDialogComponent, {
      height: '400px',
      width: '600px',
      autoFocus: false
    });
  }


  async ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }
}
